import { render, staticRenderFns } from "./skus.vue?vue&type=template&id=38e9c24e&scoped=true"
import script from "./skus.vue?vue&type=script&lang=js"
export * from "./skus.vue?vue&type=script&lang=js"
import style0 from "./skus.vue?vue&type=style&index=0&id=38e9c24e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "38e9c24e",
  null
  
)

export default component.exports