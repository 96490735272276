<template>
  <div class="web-billing"
       v-loading="loading"
       element-loading-text="正在下单"
       element-loading-spinner="el-icon-loading"
       element-loading-background="rgba(0, 0, 0, 0.8)"
  >
    <div class="big-left">
      <div class="bl-top">
        <div class="bt-item">
          <span class="bt-number">{{serviceNo}}</span>
          <span class="bt-text">服务号</span>
        </div>
        <div class="bt-item" style="cursor: pointer" :class="{activeSex:tabIndex==0}" @click="choicePage(0)">
          <span class="bt-price">¥ {{itemTotal}}</span>
          <span class="bt-name">项目</span>
        </div>
        <div class="bt-item" style="cursor: pointer" :class="{activeSex:tabIndex==1}" @click="choicePage(1)">
          <span class="bt-price">¥ {{skuTotal}}</span>
          <span class="bt-name">卖品</span>
        </div>
        <div class="bt-item" style="cursor: pointer" :class="{activeSex:tabIndex==2}" @click="choicePage(2)">
          <span class="bt-price">¥ {{specTotal}}</span>
          <span class="bt-name">成品</span>
        </div>
      </div>
      <div class="bl-bottom">
        <div class="bb-item" @click="toMemberList">
          <span>查询会员</span>
        </div>
        <div class="bb-item" @click="toAddMember">
          <span>创建会员</span>
        </div>
        <div class="bb-item" v-if="member.id&&memberCard.id">
          <span v-if="memberCard.cardName">{{memberCard.cardName}}</span>
          <span style="margin-top: 6px;color: red;font-size: 13px"
                v-if="memberCard.cardName">¥ {{memberCard.balance}}</span>
        </div>
        <div class="bb-item" v-if="member.id">
          <img v-if="!member.headPortrait&&member.sex==0" src="../../../assets/images/nan.png" class="bc-img">
          <img v-if="!member.headPortrait&&member.sex==1" src="../../../assets/images/nan.png" class="bc-img">
          <img v-if="!member.headPortrait&&member.sex==2" src="../../../assets/images/nv.png" class="bc-img">
          <img v-if="member.headPortrait" :src="member.headPortrait" class="bc-img">
          <span>{{member.memberName}}</span>
        </div>
      </div>
    </div>
    <div class="big-right">
      <div class="br-top">
        <TabItem
          v-if="tabIndex == 0"
          ref="tabItem"
          :itemData="itemData"
          :itemTotal="itemTotal"
          :mc="memberCard"
          @setItems="setItems"
        />
        <TabSku
          v-if="tabIndex == 1"
          ref="tabSku"
          :skuData="skuData"
          :skuTotal="skuTotal"
          :mc="memberCard"
          @setSkus="setSkus"
        />
        <TabSpec
          v-if="tabIndex == 2"
          ref="specSku"
          :specData="specData"
          :specTotal="specTotal"
          :mc="memberCard"
          :memberId="memberId"
          @setSpecs="setSpecs"
        />
      </div>
      <div class="br-bottom">
        <div class="br-btn">
          <div style="width: 50%;">
            <span class="br-sex" :class="{activeSex:orderSex==1}">男</span>
            <span class="br-sex" :class="{activeSex:orderSex==2}">女</span>
          </div>
          <div style="width: 50%;float: right;display: flex;flex-direction: row">
            <span class="zj">总计：</span>
            <span class="total">￥{{ total }}</span>
            <button class="payment" @click="saveBillingOrder()">结 算</button>
          </div>
        </div>
      </div>
    </div>

    <TabMemberList
      v-if="mbVisible"
      :mbVisible="mbVisible"
      :mbType="mbType"
      @setMember="setMember"
      @setMemberCard="setMemberCard"
      @closeML="closeML"
    />

    <TabSaveMember
      v-if="smVisible"
      :smVisible="smVisible"
      @setMember="setMember"
      @closeSM="closeSM"
    />

    <TabPayment
      v-if="payVisible"
      :payVisible="payVisible"
      :total="total"
      :member="member"
      :memberCard="memberCard"
      :skuData="skuData"
      @closePay="closePay"
      :data="data"
      :type="type"
    />
  </div>
</template>

<script>
  import {getToken} from "../../../utils/auth";
  import TabMemberList from "../../deposit/memberList";
  import TabSaveMember from "../../common/member/saveMember";
  import TabPayment from "./payment/payment";
  import TabItem from "./menber/items";
  import TabSku from "./menber/skus";
  import TabSpec from "./menber/specs";

  export default {
    name: "qkkd",
    components: {
      TabMemberList,
      TabSaveMember,
      TabPayment,
      TabItem,
      TabSku,
      TabSpec,
    },
    data() {
      return {
        mbType: 'item',
        type: '',
        orderId: '',
        orderSex: 1,
        tabIndex: 0,

        serviceNo: '',
        items: [],

        itemData: [],
        skuData: [],
        specData: [],

        mcId: '',
        memberId: '',
        member: {},
        memberCard: {},

        miNames: [],
        memberItem: {},

        itemTotal: 0,
        skuTotal: 0,
        specTotal: 0,
        total: 0,
        data: {},

        saveVisible: false,
        mbVisible: false,
        smVisible: false,
        mcVisible: false,
        miVisible: false,
        payVisible: false,
        shopVisible: false,
        loading: false,
      };
    },

    async created() {
      this.getServiceNo()
    },

    methods: {
      async getServiceNo() {
        let res = await this.$get("/sw/getServiceNo", {token: getToken()});
        this.serviceNo = res.serviceNo
      },

      //选择页面
      choicePage(index) {
        this.tabIndex = index
      },

      //设置项目列表
      setItems(items, total, mi) {
        this.itemData = items
        this.itemTotal = parseFloat(total)
        var to = parseFloat(this.skuTotal) + parseFloat(this.itemTotal) + parseFloat(this.specTotal)
        this.total = to.toFixed(1)
        if (mi && mi.memberItemId) {
          this.miNames.forEach(item => {
            item.memberItems.forEach(it => {
              if (it.id == mi.memberItemId) {
                it.uses = it.uses - 1
              }
            })
          })
        }
      },

      //设置卖品列表
      setSkus(skus, total) {
        this.skuData = skus
        this.skuTotal = parseFloat(total)
        var to = parseFloat(this.skuTotal) + parseFloat(this.itemTotal) + parseFloat(this.specTotal)
        this.total = to.toFixed(1)
      },

      //设置成品列表
      setSpecs(specs, total) {
        this.specData = specs
        this.specTotal = parseFloat(total)
        var to = parseFloat(this.skuTotal) + parseFloat(this.itemTotal) + parseFloat(this.specTotal)
        this.total = to.toFixed(1)
      },

      toMemberList() {
        this.mbVisible = true
      },
      toAddMember() {
        this.smVisible = true;
      },

      //选择会员信息
      setMember(item) {
        this.smVisible = false
        if (item) {
          this.member = item;
          this.activeSex = item.sex
          this.memberId = item.id
          if (item.memberCards.length > 0) {
            this.memberCard = item.memberCards[0]
            this.mcId = this.memberCard.id
          } else {
            this.memberCard = {}
            this.mcId = ''
          }
        }
        // if (this.$refs.tabItem) {
        //   this.$refs.tabItem.setItemsPrice(this.memberCard)
        // }
        // if (this.$refs.tabSku) {
        //   this.$refs.tabSku.setSkusPrice(this.memberCard)
        // }
        this.closeML();
      },

      setMemberCard(mb, mc) {
        this.smVisible = false
        if (mb.id) {
          this.member = mb;
          this.memberId = mb.id
          this.activeSex = mb.sex
        }
        if (mc.id) {
          this.memberCard = mc
          this.mcId = mc.id
        }
        this.closeML();
      },


      async saveBillingOrder() {
        if (!this.memberId){
          this.$message.error("请选择会员！")
          return ;
        }
        let mcId = ''
        if (this.memberCard && this.memberCard.id) {
          mcId = this.memberCard.id
        }
        var data = {
          itemStr: JSON.stringify(this.itemData),
          skuStr: JSON.stringify(this.skuData),
          specStr: JSON.stringify(this.specData),
          total: this.total,
          memberId: this.memberId,
          mcId: mcId,
          orderSex: this.orderSex,
          token: getToken(),
          serviceNo: this.serviceNo,
          payType: '',
          hh: null,
        }
        this.data = data
        this.payVisible = true;
      },

      closePay() {
        this.payVisible = false;
      },

      closeSM() {
        this.smVisible = false;
      },

      cleanData() {
      },

      toMembers() {
        this.mbVisible = true;
      },
      closeML() {
        this.mbVisible = false;
      },

    },

  };
</script>


<style lang="scss" scoped>
  .web-billing {
    width: 100%;
    height: 100%;
    background-color: #F2F2F2;
    margin-right: 5px;
    display: flex;
    flex-direction: row;
  }

  .big-left {
    width: 78px;
    border-right: #DDDDDD solid 1px;
    height: 100vh;
  }

  .bl-top {
    height: 50%;
  }

  .bt-item {
    display: flex;
    flex-direction: column;
    text-align: center;
    height: 20%;
    justify-content: center;
    align-items: center;
    border-bottom: #DDDDDD solid 1px;
  }

  .bt-number {
    color: #ff4d51;
    font-size: 28px;
    margin-bottom: 8px;
    font-weight: bold;
  }

  .bt-text {
    color: #A2A2A2;
    font-size: 15px;
  }

  .bt-name {

  }

  .bt-price {
    width: 60px;
    color: #FFFFFF;
    background-color: #DE4673;
    margin-top: -6px;
    margin-bottom: 10px;
    height: 18px;
    line-height: 18px;
    border-radius: 5px;
  }

  .bl-bottom {
    display: flex;
    flex-direction: column-reverse;
    height: 50%;
    width: 80px;
  }

  .bb-user {
    height: 15%;
  }

  .bb-card {
    height: 15%;
  }

  .bb-tc {
    height: 15%;
  }

  .bb-shop {
    height: 15%;
  }

  .bb-item {
    height: 70px;
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: center;
    justify-content: center;
    border-top: #DDDDDD solid 1px;
    font-size: 8px;
    cursor: pointer;
  }

  .bb-item span {

  }

  .bc-img {
    width: 40px;
    height: 40px;
    border-radius: 20px;
    margin-bottom: 6px;
  }

  .big-right {
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow: hidden;
  }

  .br-top {
    height: 100%;
    overflow: hidden;
  }

  .br-bottom {
    width: 100%;
    height: 60px;
    border-top: #DDDDDD solid 1px;
  }

  .br-btn {
    width: 100%;
    /*height: 100%;*/
    float: right;
    display: flex;
    align-items: center;
  }

  .br-btn .zj {
    width: 55%;
    line-height: 60px;
    text-align: right;
  }

  .br-btn .total {
    width: 20%;
    line-height: 60px;
    color: red;
  }

  .payment {
    width: 25%;
    height: 60px;
    border: 0;
    background-color: #e82742;
    color: #fff;
    cursor: pointer;
  }

  .br-save {
    width: 150px;
    height: 60px;
    border: 0;
    background-color: #595959;
    color: #fff;
    cursor: pointer;
    margin-left: 18px;
  }

  .br-sex {
    display: inline-block;
    background-color: #C2C6CD;
    margin-left: 12px;
    width: 32px;
    text-align: center;
    line-height: 32px;
    height: 32px;
    border-radius: 45px;
    font-size: 16px;
    cursor: pointer;
  }

  .activeSex {
    background-color: #666666;
    color: #ffffff;
  }

</style>
